import React from 'react';
import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import { useLocation, useRoutes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const navHeader = { text: 'Wedding software', href: '/' };
export const subPages = [
  { type: 'link', text: 'Q&A', href: '/questions' },
  { type: 'link', text: 'Photos', href: '/photos' },
  { type: 'link', text: 'Wedding party', href: '/party' },
  { type: 'link', text: 'Schedule', href: '/schedule' },
] as const
export const navItems: SideNavigationProps['items'] = [
  { type: 'link', text: 'Home', href: '/' },
  {
    type: 'section',
    text: 'Manage',
    items: subPages,
  }
];

interface NavigationProps {
  header?: SideNavigationProps['header'];
  items?: SideNavigationProps['items'];
  onFollowHandler?: SideNavigationProps['onFollow'];
}

export const Navigation = ({
  header = navHeader,
  items = navItems,
}: NavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return <SideNavigation items={items} header={header} activeHref={location.pathname} onFollow={(e) => {
    e.preventDefault()
    navigate(e.detail.href)
  }} />;
}