import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, ColumnLayout, Container, ContentLayout, Grid, Header, SpaceBetween } from '@cloudscape-design/components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ActionLink } from './ActionLink';
import { Details } from './Details';

export const Home = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate()

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
        >
          Welcome, {user.attributes?.name}
        </Header>
      }
    >
      <SpaceBetween size='l' direction='vertical'>
        <Details />
        <ColumnLayout
          columns={2}
        >
          <ActionLink
            href='/questions'
            title='Answer questions'
            shortTitle='questions'
            description='View questions from our guests, and add new entires to the Q&A.'
            icon='suggestions'
          />
          <ActionLink
            href='/photos'
            title='Manage photos'
            shortTitle='photos'
            description='Manage the library of photos for use on the website. Also view the photos uploaded by our guests!'
            icon='multiscreen'
          />
        </ColumnLayout>
      </SpaceBetween>
    </ContentLayout>
  )
};
