import BreadcrumbGroup, { BreadcrumbGroupProps } from '@cloudscape-design/components/breadcrumb-group';
import { useLocation, useNavigate } from 'react-router-dom';
import { subPages } from '../navigation';

export const Breadcrumbs = () => {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <BreadcrumbGroup
      items={[{ text: 'Home', href: '/' }, ...subPages.filter(page => page.href === location.pathname)]}
      onClick={(e) => navigate(e.detail.href)}
      expandAriaLabel="Show path"
      ariaLabel="Breadcrumbs"
    />
  );
}