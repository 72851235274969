const PROD = "admin.catherine-jose.wedding"

export const getEnv =() => {
  switch(window.location.hostname) {
    case PROD:
      return {
        userPoolId: "us-east-1_BWLgF8qOC",
        clientId: "27qg5v4uj69il8mlgjbrjh8tjj",
        unauthRoleArn: "arn:aws:iam::913847713128:role/Prod-AuthStack-ProdIdentityPoolUnauthenticatedRole-2Y0LHXBUXIPB",
        identityPoolId: "us-east-1:cedf8973-0518-4ef2-b7e9-f02b89b37b31",
        rumApplicationId: "0766f235-29b5-4f18-a86f-9355fe1b6805",
        s3Name: "prod-catherine-jose-wedding",
        appSyncEndpoint: "https://wvkphetsm5cq5cv4hb5ehe5ozu.appsync-api.us-east-1.amazonaws.com/graphql",
      };
    default: // localhost, beta, etc.
      return {
        userPoolId: "us-east-1_WhNuLchdJ",
        clientId: "2ocoqsf1ocfu50uv7glimt6f1k",
        unauthRoleArn: "arn:aws:iam::913847713128:role/Beta-AuthStack-BetaIdentityPoolUnauthenticatedRole-1K95NWVYUH3PE",
        identityPoolId: "us-east-1:da647025-81c7-4d9b-a678-dad96fd30f9e",
        rumApplicationId: "c1a1d651-aa92-433e-9844-46b770819335",
        s3Name: "beta-catherine-jose-wedding",
        appSyncEndpoint: "https://cyo35pmobrazhgrhv2bt4cpega.appsync-api.us-east-1.amazonaws.com/graphql",
      };
  }
}