import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Button, Container, ContentLayout, Header, SpaceBetween, Table } from "@cloudscape-design/components"
import { useGetQuestionsQuery } from "@wedding/api-schema/graphql-apollo"

export const QuestionsAndAnswers = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const {
    data,
    loading
  } = useGetQuestionsQuery()

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description="Answer questions for the FAQ page of the website"
        >
          Answer questions
        </Header>
      }
    >
      <SpaceBetween size='l' direction='vertical'>
        <Container>
          <Table
            items={data?.getQuestions || []}
            loading={loading}
            selectionType="multi"
            columnDefinitions={[
              {
                id: 'date',
                header: 'Date',
                cell: (item: any) => new Date(item.date).toLocaleDateString(),
              },
              {
                id: 'name',
                cell: (item: any) => item.name,
                header: 'Name',
                minWidth: 80,
              },
              {
                id: 'question',
                header: 'Question',
                cell: (item: any) => item.question,
                minWidth: 300,
              },
              {
                id: 'email',
                header: 'Email',
                cell: (item: any) => item.email,
                minWidth: 100,
              },
            ]}
            variant="embedded"
            header={
              <Header
                variant='h2'
                counter={`(${data?.getQuestions.length ?? 0})`}
                actions={
                  <SpaceBetween size="xs" direction="horizontal">
                    <Button disabled>Delete</Button>
                  </SpaceBetween>
                }
              >
                Questions
              </Header>
            }
            empty={
              <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                <SpaceBetween size="xxs">
                  <div>
                    <b>No files</b>
                    <Box variant="p" color="inherit">
                      Try uploading a file.
                    </Box>
                  </div>
                </SpaceBetween>
              </Box>
            }
          />
        </Container>
      </SpaceBetween>
    </ContentLayout>
  )
}