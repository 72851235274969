import { Loader } from "@aws-amplify/ui-react"
import { Box, Button, ButtonDropdown, Cards, ColumnLayout, Grid, Header, Modal, ProgressBar, SpaceBetween, StatusIndicator } from "@cloudscape-design/components"
import { MasonryPhoto, MasonryPhotoStatus, useListSimilarPhotosLazyQuery, useListSimilarPhotosQuery } from "@wedding/api-schema/graphql-apollo"
import { useEffect } from "react"
import S3Image from "../../components/s3-image"
import { getStatusIndicatorType, updateImagesMap } from "./Photos"

interface PhotoModalProps {
  modalVisible: boolean
  setModalVisible: (visible: boolean) => void
  setSelectedModalItemId: (item: string) => void
  setImages: (items: MasonryPhoto[]) => void
  selectedModalItemId: string | undefined
  setSelectedItems: (items: MasonryPhoto[]) => void,
  selectedItems: MasonryPhoto[],
  allItems: readonly MasonryPhoto[]
  modalActions: any
  modalData: any
}

enum AccessLevel {
  Public = "public",
  Private = "private",
  Protected = "protected"
}

export const PhotoModal = ({
  modalVisible,
  setModalVisible,
  selectedModalItemId,
  setSelectedModalItemId,
  setImages,
  allItems,
  modalActions,
  setSelectedItems,
  selectedItems,
  modalData,
}: PhotoModalProps) => {
  const selectedModalItemIndex = allItems.findIndex(item => item.id === selectedModalItemId)
  const selectedModalItem = allItems[selectedModalItemIndex]
  const [getSimilarPhotos, {
    data: similarPhotosData,
    loading: similarPhotosLoading
  }] = useListSimilarPhotosLazyQuery()

  useEffect(() => {
    if (selectedModalItemId) {
      getSimilarPhotos({
        variables:{
          id: selectedModalItemId
        }
      })
    }
  }, [getSimilarPhotos, modalVisible, selectedModalItemId])

  const footerProps = {
    modalVisible,
    setModalVisible,
    selectedModalItemId,
    setSelectedModalItemId,
    setImages,
    setSelectedItems,
    selectedItems,
    allItems,
    selectedModalItem,
    selectedModalItemIndex,
    modalActions,
    modalData,
    similarPhotos: similarPhotosData?.listSimilarPhotos.items,
    similarPhotosLoading,
  }

  if (!selectedModalItem) {
    return <></>
  }
  
  return (
    <Modal
      onDismiss={() => setModalVisible(false)}
      visible={!!selectedModalItemId && modalVisible}
      closeAriaLabel="Close modal"
      footer={<PhotoModalFooter {...footerProps}/>}
      header={<span>{selectedModalItemIndex + 1}/{allItems.length}</span>}
      size="large"
    >
      <SpaceBetween size="l">
        <Box
          textAlign="center"
        >
          <div
            style={{
              display: "flex",
              height: "54vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <S3Image
              imgKey={`photos/${selectedModalItem.id}.standard.webp`}
              level={AccessLevel.Public}
              style={{
                maxHeight: "100%",
                margin: "auto",
                display: "block",
              }}
            />
          </div>
        </Box>
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }, { colspan: 2 }]} >
          <Box>
            <Button
              iconName="angle-left"
              variant="icon"
              onClick={() => {
                const indexMinusOne = selectedModalItemIndex - 1
                const nextItemIndex = indexMinusOne < 0 ? allItems.length - 1 : indexMinusOne
                setSelectedModalItemId(allItems[nextItemIndex].id)
              }}
            />
          </Box>
          <div style={{ display: "flex", justifyContent: "center"}}>
            <ButtonDropdown
              loading={modalData.loadingListPhotos}
              onItemClick={async (e) => {
                if (e.detail.id === "delete") {
                  await modalActions.deletePhoto({
                    variables: {
                      id: selectedModalItem.id
                    }
                  })
                  setImages(allItems.filter(img => img.id !== selectedModalItem.id))
                } else if (e.detail.id === "publish") {
                  await modalActions.publishPhoto({
                    variables: {
                      id: selectedModalItem.id
                    }
                  })
                  setImages(allItems.map(updateImagesMap({
                    id: selectedModalItem.id,
                    status: MasonryPhotoStatus.Published
                  })))
                } else if (e.detail.id === "unpublish") {
                  await modalActions.unpublishPhoto({
                    variables: {
                      id: selectedModalItem.id
                    }
                  })
                  setImages(allItems.map(updateImagesMap({
                    id: selectedModalItem.id,
                    status: MasonryPhotoStatus.Draft
                  })))
                } else if (e.detail.id === "select") {
                  const photoToSelect = allItems.find(photo => photo.id === selectedModalItem.id)
                  if (photoToSelect) {
                    setSelectedItems([...selectedItems, photoToSelect])
                  }
                }
              }}
              items={[
                {
                  text: "Publishing",
                  items: [
                    {
                      text: "Publish",
                      id: "publish",
                      disabled: selectedModalItem.status === MasonryPhotoStatus.Published,
                    },
                    {
                      text: "Make draft",
                      id: "unpublish",
                      disabled: selectedModalItem.status === MasonryPhotoStatus.Draft,
                    }
                  ]
                },
                {
                  text: "List",
                  items: [
                    {
                      text: "Select",
                      description: "Select photo in the list",
                      id: "select",
                    }
                  ]
                }
              ]}
              variant="primary"
            >
              Actions
            </ButtonDropdown>
          </div>
          <Box textAlign="right">
            <Button
              iconName="angle-right"
              variant="icon"
              onClick={() => {
                const indexPlusOne = selectedModalItemIndex + 1
                const nextItemIndex = indexPlusOne >= allItems.length ? 0 : indexPlusOne
                setSelectedModalItemId(allItems[nextItemIndex].id)
              }}
            />
          </Box>
        </Grid>
      </SpaceBetween>
    </Modal>
  )
}

type PhotoModalFooterProps = PhotoModalProps & {
  selectedModalItem: MasonryPhoto
  selectedModalItemIndex: number
  similarPhotos?: {
    id: String
    status?: MasonryPhotoStatus | null
    similarity?: number | null
  }[]
  similarPhotosLoading: boolean
}

const PhotoModalFooter = (props: PhotoModalFooterProps) => {
  return (
    <SpaceBetween size="xl">
      <ColumnLayout columns={2} variant="default">
        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">Status</Box>
            <StatusIndicator type={getStatusIndicatorType(props.selectedModalItem)}>
              {props.selectedModalItem.status}
            </StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">Upload Date</Box>
            <div>{props.selectedModalItem.uploadDate ? new Date(props.selectedModalItem.uploadDate).toLocaleDateString() : "N/A"}</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">Author</Box>
            <div>{props.selectedModalItem.author}</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">Dominant Color</Box>
            <SpaceBetween size="xs" direction="horizontal">
              <div style={{
                display: "block",
                backgroundColor: props.selectedModalItem.dominantColor ?? "#fff",
                width: "18px",
                height: "18px",
                borderRadius: "2px",
              }}/>
              <div>{props.selectedModalItem.dominantColor}</div>
            </SpaceBetween>
          </div>
        </SpaceBetween>
      </ColumnLayout>
      <Cards
        cardDefinition={{
          header: item => (
            <div
              onClick={() => props.setSelectedModalItemId(item.id.toString())}
              style={{
                cursor: "pointer",
                maxHeight: "220px",
                height: "220px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <S3Image
                imgKey={`photos/${item.id}.standard.webp`}
                level={AccessLevel.Public}
                style={{
                  maxHeight: "100%",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
          ),
          sections: [
            {
              header: "Status",
              content: (item) => {
                const currentPhoto = props.allItems.find(currItem => currItem.id === item.id)
                return (
                  <StatusIndicator type={getStatusIndicatorType(currentPhoto ?? {})}>
                    {currentPhoto?.status || "N/A"}
                  </StatusIndicator>
                )
              }
            },
            {
              header: "Similarity",
              content: (item) => (
                <ProgressBar value={((item.similarity ?? 0) / 4) * 100} variant="key-value" />
              )
            }
          ]
        }}
        cardsPerRow={[
          { cards: 1 },
          { minWidth: 500, cards: 2 }
        ]}
        items={props.similarPhotos || []}
        loading={props.similarPhotosLoading}
        loadingText="Loading similar photos"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No similar photos</b>
          </Box>
        }
        header={<Header
            variant="h3"
            counter={props.similarPhotosLoading ? "" : props.similarPhotos?.length.toString() ?? "0"}
          >Similar photos</Header>}
      />
    </SpaceBetween>
  )
}