import React, { useCallback, useContext } from 'react';
// import logo from './logo.svg';
import './App.css';
import SignedInRoutes from './pages/SignedInRoutes';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { getEnv } from './utils/environment';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { CancelableEventHandler } from '@cloudscape-design/components/internal/events';
import { AuthContext, IAuth } from './contexts/authContext';
import { useState } from 'react';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';


Amplify.configure({
  Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "us-east-1",

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: getEnv().userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: getEnv().clientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
      
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link' 

      identityPoolId: getEnv().identityPoolId,

      // OPTIONAL - Configuration for cookie storage
      // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //     domain: getEnv().,
      // // OPTIONAL - Cookie path
      //     path: '/',
      // // OPTIONAL - Cookie expiration in days
      //     expires: 365,
      // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //     sameSite: "strict" | "lax",
      // // OPTIONAL - Cookie secure flag
      // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //     secure: true
      // },

      // OPTIONAL - customized storage object
      // storage: MyStorage,
      
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },

       // OPTIONAL - Hosted UI configuration
      // oauth: {
      //     domain: 'beta-catherine-jose-wedding.auth.us-east-1.amazoncognito.com',
      //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      //     redirectSignIn: 'http://localhost:3000/',
      //     redirectSignOut: 'http://localhost:3000/',
      //     // callbackUrl: "http://localhost:3000/",
      //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      // }
  },
  Storage: {
    AWSS3: {
      bucket: getEnv().s3Name, // (required) -  Amazon S3 bucket name
      region: 'us-east-1' // (optional) -  Amazon service region
    }
  }
});

const i18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};

function App() {
  return (
    <div className="App">
      <Authenticator loginMechanisms={["username"]} hideSignUp variation='modal'>
        <Authenticator.Provider>
          <SignedInRoutes />
        </Authenticator.Provider>
      </Authenticator>
    </div>
  );
}

export default App;
