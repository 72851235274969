import { Box, Button, ColumnLayout, Container, Grid, Header, Icon, IconProps, SpaceBetween } from "@cloudscape-design/components"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from 'usehooks-ts'

export const ActionLink = (props: {
  href: string
  title: string
  shortTitle: string
  description: string
  icon: IconProps.Name
}) => {
  const navigate = useNavigate()
  // const matches = useMediaQuery('(min-width: 1111px)')
  return (
    <Container
      fitHeight
      header={
        <Grid gridDefinition={[{colspan: 10}, { colspan: 2}]}>
          <Header
            variant="h2"
            description={props.description}
          >
            {props.title}
          </Header>
          <Box float="right">
            <Icon name={props.icon} size="medium" variant="subtle"/>
          </Box>
        </Grid>
      }
      footer={
        <Button onClick={() => navigate(props.href)}>Go to {props.shortTitle}</Button>
      }
    >
    </Container>
  )
}