import { FileUploader } from "@aws-amplify/ui-react"
import { useCollection } from "@cloudscape-design/collection-hooks"
import { Box, Button, ButtonDropdown, ColumnLayout, Container, ContentLayout, Header, Icon, Input, Modal, Popover, ProgressBar, Select, SpaceBetween, Spinner, StatusIndicator, StatusIndicatorProps, Table, Tiles } from "@cloudscape-design/components"
import { Mode } from "@cloudscape-design/global-styles"
import { ListMasonryImagesQuery, MasonryPhoto, MasonryPhotoStatus, ReProcessMasonryPhotoInputOperation, useDeleteMasonryPhotoMutation, useListMasonryImagesLazyQuery, useListMasonryImagesQuery, usePhotoProcessingUpdateSubscription, usePhotoUploadUpdateSubscription, usePublishMasonryPhotoMutation, useReProcessMasonryPhotoMutation, useUnpublishMasonryPhotoMutation, useUpdateMasonryPhotoDetailsMutation, useUploadMasonryPhotoMutation } from "@wedding/api-schema/graphql-apollo"
import { uniqBy } from "lodash"
import { useEffect, useState } from "react"
import S3Image from "../../components/s3-image"
import { PhotoModal } from "./PhotoModal"

enum AccessLevel {
  Public = "public",
  Private = "private",
  Protected = "protected"
}

const isPhotoAvailable = (img: MasonryPhoto) => ![MasonryPhotoStatus.Processing, MasonryPhotoStatus.ProcessingError].includes(img.status)

export const updateImagesMap = (update: Partial<MasonryPhoto> & { id: string }) => (img: MasonryPhoto) => {
  if (img.id !== update.id) {
    return img
  }
  return {
    ...img,
    ...update
  }
}

export const getStatusIndicatorType = (img: { status?: MasonryPhotoStatus | null }): StatusIndicatorProps.Type => {
  switch(img.status) {
    case MasonryPhotoStatus.Processing:
      return "in-progress"
    case MasonryPhotoStatus.Draft:
      return "info"
    case MasonryPhotoStatus.Published:
      return "success"
    default:
      return "error"
  }
}

export const Photos = () => {
  const [fetchImages, {
    data: listMasonryImagesData,
    loading: loadingListPhotos
  }] = useListMasonryImagesLazyQuery()
  useEffect(() => {
    fetchImages()
  }, [])
  const [images, setImages] = useState<MasonryPhoto[]>([])
  const initialImages = listMasonryImagesData?.listMasonryImages ?? [] as const
  useEffect(() => {
    if (!loadingListPhotos && initialImages.length !== images.length) {
      setImages(uniqBy([...images, ...initialImages], "id"))
    }
  }, [initialImages, loadingListPhotos])
  const [uploadMasonryPhoto, { loading: loadingUpload }] = useUploadMasonryPhotoMutation()
  const [selectedItems, setSelectedItems] = useState<MasonryPhoto[]>([])
  const [deletePhoto, { loading: loadingDelete }] = useDeleteMasonryPhotoMutation()
  const [publishPhoto, { loading: loadingPublish }] = usePublishMasonryPhotoMutation()
  const [unpublishPhoto, { loading: loadingUnpublish }] = useUnpublishMasonryPhotoMutation()
  const [reprocessPhoto, { loading: loadingReprocessPhoto }] = useReProcessMasonryPhotoMutation()
  const [updatePhotoDetails, { loading: loadingUpdatePhotoDetails }] = useUpdateMasonryPhotoDetailsMutation()
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedModalItemId, setSelectedModalItemId] = useState<string>()
  const {
    data: processingUpdate,
    loading: processingUpdateLoading,
  } = usePhotoProcessingUpdateSubscription()
  useEffect(() => {
    const imageUpdate = processingUpdate?.onUpdateMasonryPhotoProcessing
    if (!processingUpdateLoading && imageUpdate) {
      setImages(images.map(updateImagesMap(imageUpdate)))
    }
  }, [processingUpdate, processingUpdateLoading])
  const {
    data: uploadUpdate,
    loading: uploadUpdateLoading,
  } = usePhotoUploadUpdateSubscription()
  useEffect(() => {
    const imageUpdate = uploadUpdate?.onUploadMasonryPhoto
    if (!uploadUpdateLoading && imageUpdate) {
      setImages(uniqBy([...images, imageUpdate], "id"))
    }
  }, [uploadUpdate, uploadUpdateLoading])
  const result = useCollection(images, {
    sorting: {
      defaultState: {
        isDescending: true,
        sortingColumn: {
          sortingComparator: (a, b) => {
            if (a.status === MasonryPhotoStatus.Published) {
              if (a.status === b.status) {
                return a.order < b.order ? -1 : 1
              }
              return 1
            }
            return -1
          },
        },
      }
    },
  })
  const modalActions = {
    fetchImages,
    deletePhoto,
    publishPhoto,
    unpublishPhoto,
    updatePhotoDetails,
  }
  const modalData = {
    loadingListPhotos
  }

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description="Manage photos throughout the website"
        >
          Manage photos
        </Header>
      }
    >
      <PhotoModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setImages={setImages}
        selectedModalItemId={selectedModalItemId}
        setSelectedModalItemId={setSelectedModalItemId}
        setSelectedItems={setSelectedItems}
        selectedItems={selectedItems}
        allItems={result.items}
        modalActions={modalActions}
        modalData={modalData}
      />
      <SpaceBetween size='l' direction='vertical'>
        <Container>
            <FileUploader
              acceptedFileTypes={['image/*']}
              accessLevel="public"
              onSuccess={({ key }) => uploadMasonryPhoto({
                variables: {
                  input: {
                    uploadUri: key
                  }
                }
              })}
            />
          </Container>
          <Container>
            <Table
              items={result.items}
              stickyHeader
              selectedItems={selectedItems}
              sortingColumn={{
                sortingField: "order",
              }}
              onSelectionChange={(e) => {
                setSelectedItems(e.detail.selectedItems)
              }}
              loading={images.length === 0 && loadingListPhotos}
              isItemDisabled={(item: MasonryPhoto) => !isPhotoAvailable(item) && item.status !== MasonryPhotoStatus.ProcessingError}
              selectionType="multi"
              submitEdit={async (photo: MasonryPhoto, cell, newValue) => {
                if (cell.id) {
                  await updatePhotoDetails({
                    variables: {
                      input: {
                        id: photo.id,
                        [cell.id]: newValue
                      }
                    }
                  })
                  await fetchImages({
                    fetchPolicy: "network-only"
                  })
                }
              }}
              columnDefinitions={[
                {
                  id: 'photo',
                  header: 'Photo',
                  width: 100,
                  cell: (item: MasonryPhoto) => isPhotoAvailable(item) ? (
                    <div
                      onClick={() => {
                        setSelectedModalItemId(item.id)
                        setModalVisible(true)
                      }}
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <Box textAlign="center">
                          <S3Image
                            imgKey={`photos/${item.id}.small.webp`}
                            level={AccessLevel.Public}
                            style={{
                              maxHeight: "100px"
                            }}
                          />
                        </Box>
                    </div>
                  ) : <Box textAlign="center"><Spinner variant="disabled"/></Box>,
                },
                {
                  id: 'status',
                  header: 'Status',
                  width: 150,
                  cell: (item: MasonryPhoto) => (
                    <StatusIndicator type={getStatusIndicatorType(item)}>
                      {item.status}
                    </StatusIndicator>
                  ),
                },
                {
                  id: 'author',
                  header: 'Author',
                  width: 120,
                  cell: (item: MasonryPhoto) => (
                    item.author
                  ),
                },
                {
                  id: 'date',
                  header: 'Upload date',
                  width: 120,
                  cell: (item: MasonryPhoto) => (
                    item.uploadDate ? new Date(item.uploadDate).toLocaleDateString() : "N/A"
                  )
                },
                {
                  id: 'order',
                  header: 'Priority',
                  width: 150,
                  cell: (item: MasonryPhoto) => item.order >= 0 ? item.order : "-",
                  editConfig: {
                    ariaLabel: "Priority",
                    editIconAriaLabel: "editable",
                    errorIconAriaLabel: "Oder Error",
                    editingCell: (
                      item,
                      { currentValue, setValue }
                    ) => {
                      return (
                        <Input
                          type="number"
                          inputMode="numeric"
                          disabled={item.status !== MasonryPhotoStatus.Published}
                          autoFocus={true}
                          value={currentValue ?? item.order}
                          onChange={event =>
                            setValue(event.detail.value)
                          }
                        />
                      );
                    }
                  }
                },
                {
                  id: 'title',
                  header: 'Title',
                  minWidth: 176,
                  cell: item => {
                    return item.title;
                  },
                  editConfig: {
                    ariaLabel: "Title",
                    editIconAriaLabel: "editable",
                    errorIconAriaLabel: "Title Error",
                    editingCell: (
                      item,
                      { currentValue, setValue }
                    ) => {
                      return (
                        <Input
                          autoFocus={true}
                          value={currentValue ?? item.title}
                          onChange={event =>
                            setValue(event.detail.value)
                          }
                        />
                      );
                    }
                  }
                },
                {
                  id: 'description',
                  header: 'Description',
                  minWidth: 250,
                  cell: (item: MasonryPhoto) => item.description,
                  editConfig: {
                    ariaLabel: "Description",
                    editIconAriaLabel: "editable",
                    errorIconAriaLabel: "Description Error",
                    editingCell: (
                      item,
                      { currentValue, setValue }
                    ) => {
                      return (
                        <Input
                          autoFocus={true}
                          value={currentValue ?? item.description}
                          onChange={event =>
                            setValue(event.detail.value)
                          }
                        />
                      );
                    }
                  }
                }
              ]}
              variant="embedded"
              header={
                <Header
                  variant='h2'
                  counter={`(${images.length})`}
                  // description="Publish, reorder, edit, and delete photos for the website. A higher priority value will render the item first."
                  actions={
                    <SpaceBetween size="xs" direction="horizontal">
                      <ButtonDropdown
                        loading={loadingDelete || loadingPublish || loadingUnpublish}
                        onItemClick={async (e) => {
                          let imageUpdates = images
                          for (const item of selectedItems) {
                            if (e.detail.id === "delete") {
                              await deletePhoto({
                                variables: {
                                  id: item.id
                                }
                              })
                              imageUpdates = imageUpdates.filter(img => img.id !== item.id)
                            } else if (e.detail.id === "publish") {
                              await publishPhoto({
                                variables: {
                                  id: item.id
                                }
                              })
                              imageUpdates = imageUpdates.map(updateImagesMap({
                                id: item.id,
                                status: MasonryPhotoStatus.Published
                              }))
                            } else if (e.detail.id === "unpublish") {
                              await unpublishPhoto({
                                variables: {
                                  id: item.id
                                }
                              })
                              imageUpdates = imageUpdates.map(updateImagesMap({
                                id: item.id,
                                status: MasonryPhotoStatus.Draft
                              }))
                            } else if (e.detail.id === "reprocess") {
                              await reprocessPhoto({
                                variables: {
                                  input: {
                                    id: item.id,
                                    operation: ReProcessMasonryPhotoInputOperation.UpdateMetadata,
                                  }
                                }
                              })
                              fetchImages()
                            }
                            setImages(imageUpdates)
                            setSelectedItems([])
                          }
                        }}
                        items={[
                          {
                            text: "Editorial",
                            disabled: loadingReprocessPhoto,
                            items: [
                              {
                                text: "Reprocess",
                                id: "reprocess",
                              }
                            ]
                          },
                          {
                            text: "Lifecycle",
                            items: [
                              {
                                text: "Delete",
                                id: "delete",
                              }
                            ]
                          },
                        ]}
                        disabled={loadingListPhotos || selectedItems.length === 0}
                        variant="primary"
                      >
                        {`Selected (${selectedItems.length})`}
                      </ButtonDropdown>
                    </SpaceBetween>
                  }
                >
                  Photo library
                </Header>
              }
              empty={
                <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                  <SpaceBetween size="xxs">
                    <div>
                      <b>No files</b>
                      <Box variant="p" color="inherit">
                        Try uploading a file.
                      </Box>
                    </div>
                  </SpaceBetween>
                </Box>
              }
            /></Container>
      </SpaceBetween>
    </ContentLayout>
  )
}