
import { Component } from 'react';
import { Storage, Logger } from 'aws-amplify';
import { StorageAccessLevel } from '@aws-amplify/storage';
import { Image } from '@aws-amplify/ui-react';

const logger = new Logger('Storage.S3Image');

interface IS3ImageProps {
	body?: any;
	contentType?: string;
	imgKey: string;
	level?: StorageAccessLevel;
	style?: any;
}

interface IS3ImageState {
	src?: any;
}

export default class S3Image extends Component<IS3ImageProps, IS3ImageState> {
	constructor(props: IS3ImageProps) {
		super(props);

		this.state = { src: null };
	}

	getImageSource() {
		const { imgKey, level } = this.props;
		Storage.get(imgKey, { level: level ? level : 'public' })
			.then(url => {
				logger.debug(url);
				this.setState({
					src: { uri: url },
				});
			})
			.catch(err => logger.warn(err));
	}

	load() {
		const { imgKey, body, contentType, level } = this.props;
		if (!imgKey) {
			logger.debug('empty imgKey');
			return;
		}

		const that = this;
		logger.debug('loading ' + imgKey + '...');
		if (body) {
			const type = contentType ? contentType : 'binary/octet-stream';
			const opt = {
				contentType: type,
				level: level ? level : 'public',
			};
			const ret = Storage.put(imgKey, body, opt);
			ret
				.then(data => {
					logger.debug(data);
					that.getImageSource();
				})
				.catch(err => logger.warn(err));
		} else {
			that.getImageSource();
		}
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps: IS3ImageProps) {
		if (
			prevProps.imgKey !== this.props.imgKey ||
			prevProps.body !== this.props.body
		) {
			this.load();
		}
	}

	render() {
		const { src } = this.state;
		if (!src) {
			return null;
		}

		const { style } = this.props;
		const photoStyle = Object.assign(
			{},
			style
		);

		return <Image style={photoStyle} src={src.uri} alt={undefined} />;
	}
}