import { Box, ColumnLayout, Container, Header, ProgressBar, SpaceBetween } from "@cloudscape-design/components"

export const Details = () => {
  const engagementDate = Date.parse("2022-07-11")
  const weddingDate = Date.parse("2023-06-03")
  const areWeMarried = (weddingDate - Date.now()) >= 0
  const engagedPercentage = (1 - (weddingDate - Date.now()) / (weddingDate - engagementDate)) * 100

  return (
    <Container
      header={
        <Header
          variant="h2"
        >
          Quick Details
        </Header>
      }
    >
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="l">
          <ProgressBar
            value={engagedPercentage}
            label="Engaged time"
            description="Time from engagement until the wedding"
            variant="key-value"
            resultText="Now married"
            status={areWeMarried ? 'in-progress' : 'success'}
          />
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Date</Box>
            <div>6/3/2023</div>
          </div>
        </SpaceBetween>

        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Location</Box>
            <div>The Barns at Mattaponi Springs</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Guest count</Box>
            <div>131</div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  )
}