import * as React from "react";
import { AppLayout, ButtonDropdownProps, TopNavigation } from "@cloudscape-design/components";
import { useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Home } from "./home/Home";
import { CancelableEventHandler } from "@cloudscape-design/components/internal/events";
import { Loader, useAuthenticator } from "@aws-amplify/ui-react";
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { QuestionsAndAnswers } from "./QuestionsAndAnswers";
import { Navigation } from "../components/navigation";
import { Breadcrumbs } from "../components/breadcrumbs";
import { Photos } from "./photos/Photos";
import { RetryLink } from "@apollo/client/link/retry";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { getEnv } from "../utils/environment";

const i18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};

export default () => {
  const { user, signOut, authStatus, route, unverifiedContactMethods } = useAuthenticator((context) => [context.user]);
  const [loaded, setLoaded] = useState(false)
  const [navigationOpen, setNavigationOpen] = useState(false)

  const itemClick = useCallback<CancelableEventHandler<ButtonDropdownProps.ItemClickDetails>>((e) => {
    if (e.detail.id === "signout") {
      signOut()
    }
  }, [user])

  React.useEffect(() => {
    user.getSession((_: unknown, session: any) => {
      if (!session.accessToken?.payload["cognito:groups"]?.includes("admin")) {
        signOut()
      } else {
        setLoaded(true)
      }
    })
  }, [user])

  const fetchToken = () => new Promise<string>((resolve, reject) => {
    user.getSession((_: unknown, session: any) => {
      resolve(session.getIdToken().getJwtToken())
    })
  })
  
  const origin = window.location.origin.includes("localhost") ? "https://cyo35pmobrazhgrhv2bt4cpega.appsync-api.us-east-1.amazonaws.com" : window.location.origin
  const client = new ApolloClient({
    link: ApolloLink.from([
      new RetryLink({
        attempts: {
          max: 5
        },
      }),
      new ApolloLink((operation, forward) => {
        return forward(operation).map((data) => {
          if (data?.errors?.some((e: any) => e.errorType === "Lambda:IllegalArgument")) {
            throw new Error('GraphQL Operational Error');
          }
          return data;
        });
      }),
      createAuthLink({
        url: origin + "/graphql",
        region: "us-east-1",
        auth: {
          jwtToken: fetchToken,
          type: "AMAZON_COGNITO_USER_POOLS",
        }
      }),
      createSubscriptionHandshakeLink({
        url: getEnv().appSyncEndpoint,
        region: "us-east-1",
        auth: {
          jwtToken: fetchToken,
          type: "AMAZON_COGNITO_USER_POOLS",
        }
      })
    ]),
    cache: new InMemoryCache(),
  });
  return (
    <BrowserRouter>
    <ApolloProvider client={client}>
      <TopNavigation
          i18nStrings={i18nStrings}
          identity={{
            href: '#',
            title: "Catherine & Jose"
          }}
          utilities={[
            {
              type: 'menu-dropdown',
              text: user.attributes?.name,
              description: user.attributes?.email || "welcome",
              iconName: 'user-profile',
              onItemClick: itemClick,
              items: [
                {
                  id: 'signout',
                  text: 'Sign out',
                }
              ],
            },
          ]}
        />
        <AppLayout
          toolsHide={true}
          navigationOpen={navigationOpen}
          onNavigationChange={() => setNavigationOpen(!navigationOpen)}
          breadcrumbs={<Breadcrumbs />}
          contentType='dashboard'
          navigation={<Navigation />}
          content={
            loaded ? 
              <Routes>
                <Route path='/' element={<Home />}/>
                <Route path="/questions" element={<QuestionsAndAnswers />}/>
                <Route path="/photos" element={<Photos />}/>
                {/* <Route path='*' element={<ErrorPage/>} /> */}
              </Routes>
            : <Loader />
          }
        />
      </ApolloProvider>
    </BrowserRouter>
  );
}